import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#A4E1E6',
        primaryDark: "#1F1F85",
        secondary: '#D4EF7F',
        secondaryDark: '#226025',
        accent: '#9F9B98', 
      }
    }
  }
});
