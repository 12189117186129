<template>

  <v-app id="fluentdata" v-scroll="onScroll">
    <v-navigation-drawer 
      v-model="drawer"
      v-if="mobile"
      clipped
      app
    >
      <v-list dense nav>
        <v-list-item link>
          <v-list-item-content>Services</v-list-item-content>
        </v-list-item>
        <!-- <v-list-item link>
          <v-list-item-content>Blog</v-list-item-content>
        </v-list-item> -->
        <v-list-item link>
          <v-list-item-content>Contact Us</v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      app
      color="white"
      elevation="1"
      class="appbar"
      :class="scrolled ? null : 'not-scrolled'"
      clipped-left
    >
      <v-app-bar-nav-icon v-if="mobile" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <img class = "app-bar-logo" src="@/assets/logo.png" @click="$router.push('/')" style="cursor: pointer;" />
      <v-spacer></v-spacer>
      <v-toolbar-items v-if="!mobile">
        <v-btn text @click="$vuetify.goTo('#services_section')">Services</v-btn>
        <!-- <v-btn text router-link to="blog">Blog</v-btn> -->
        <v-btn text @click="$vuetify.goTo('#about_section')">About Us</v-btn>
        <v-btn text @click="$vuetify.goTo('#contact_section')">Contact</v-btn>
      </v-toolbar-items>
    </v-app-bar>

    <v-main app :class="scrolled ? null : 'not-scrolled'">
      <router-view/>
    </v-main>
    <v-footer app v-if="!mobile">
      <v-spacer></v-spacer>
      © 2024 FluentData LLC
      <v-spacer></v-spacer>
    </v-footer>
  </v-app>

</template>

<script>

export default {
  name: 'App',

  data: () => ({
    scrolled: false,
    drawer: false
  }),
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.mobile
    }
  },
  methods: {
    onScroll() {
      if(window.pageYOffset > 20) {
        this.scrolled = true
      } else {
        this.scrolled = false
      }
    }
  },
  mounted() {

  }
};
</script>

<style>


  @media screen and (max-device-width: 480px) and (orientation: portrait) {
/* 
    .appbar.not-scrolled {
      margin-top: 0 !important;
    }

    .appbar:not(.not-scrolled) {
      transition: margin-top 1s;
    }

    .v-main {
      padding-top: 0 !important;
      transition: padding-top 1s;
    } */

  }

  /* .appbar.not-scrolled {
    margin-top: -64px !important;
    transition: margin-top 1s;
  }

  .appbar:not(.not-scrolled) {
    transition: margin-top 1s;
  }

  .v-main {
    padding-top: 0 !important;
    transition: padding-top 1s;
  } */

  /* .v-main:not(.not-scrolled) {
    transition: padding-top 1s;
  } */

  .app-bar-logo {
    height: 100%;
  }

  .section-header {
    color: #1F1F85;
    text-align: center;
    width: 100%;
    padding-top: 0px;
    padding-bottom: 20px;
    font-size: 1.3em;
    text-decoration: underline;
    font-variant: small-caps;
  }

</style>