<template>
  <h1>Blog</h1>
</template>

<script>

  export default {
    name: 'BlogView',
  }
</script>

<style scoped>

  .hero {
    top: 0;
    left: 0vw;
    width: 100vw;
    height: calc(100vh - 100px);
    background-image: url("@/assets/hero.jpg");
    background-size: cover;
  }

  .logo {
    position: absolute;
    left: 50vw;
    right: -10vw;
    top: 20vh;
    padding: 5ch 10ch;
  }

  .logo > div {
    width: 60%; 
    text-align: center;
  }

  .logo img {
    width: 100%;
  }

</style>