import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/Home.vue'
import training from "@/views/Training.vue"
import blog from "@/views/Blog.vue"
import about from "@/views/About.vue"
import services from "@/views/Services.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: home,
  },
  {
    path: "/services",
    name: "services",
    component: services,
  },
  {
    path: "/training",
    name: "training",
    component: training,
  },
  {
    path: "/blog",
    name: "blog",
    component: blog,
  },
  {
    path: "/about",
    name: "about",
    component: about,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
