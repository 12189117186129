import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'


const startup = async () => {

  const components = ["services", "blog", "training", "about", "infobox", "contact", "hero"]
  
  for(let i = 0; i < components.length; i++) {
    let component = components[i]
    let module = await import(`@/components/${component}.vue`)
    Vue.component(module.default.name, module.default)
  }
  
  new Vue({
    router,
    vuetify,
    render: h => h(App)
  }).$mount('#app')  

}

startup()


