<template>
  <v-container fluid height = "200vh" class="pa-0">
    <fd-hero></fd-hero>
    <v-container>
      <fd-services></fd-services>
      <fd-about-us></fd-about-us>
      <fd-contact></fd-contact>
    </v-container>
    <v-footer v-if="$vuetify.breakpoint.mobile">
      <v-spacer></v-spacer>
      © 2022 FluentData LLC
      <v-spacer></v-spacer>
    </v-footer>
  </v-container>
</template>

<script>

  export default {
    name: 'HomeView',
    data() {
      return {
      }
    },
    methods: {

    },
    watch: {

    }
  }
</script>